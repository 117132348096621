import React, { createContext, useCallback, useEffect, useRef, useState, } from 'react';
import deepmerge from 'deepmerge';
import { useDispatch } from 'react-redux';
import RatesService from '../services/ratesService';
import showNotification from '../shared/helpers/notifications';
import OldRatesService from '../services/oldRatesService';
import CurrencyConversionService from '../services/currencyConversionService';
import { CLOSE_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
import { getEnvSettings } from '../config/environmentSettings';
export const RatesContext = createContext({});
export const ratesRanges = (data) => {
    const postDataKeys = Object.keys(data);
    const ranges = {};
    postDataKeys.forEach((currency) => {
        let count = 0;
        ranges[currency] = [];
        data[currency].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data[currency].length; i++) {
            if (data[currency][i + 1] && data[currency][i + 1].rate !== data[currency][i].rate) {
                const n = count !== 0 ? count + 1 : 0;
                const start = new Date(data[currency][n].date).setMinutes(0, 0, 0);
                const end = new Date(data[currency][i].date).setMinutes(59, 59, 999);
                ranges[currency].push({ rate: data[currency][i].rate, end, start });
                count = i;
            }
            // work out the last in the array
            // TODO: some better way
            if (i === data[currency].length - 1) {
                const start = new Date(data[currency][count].date).setMinutes(0, 0, 0);
                const end = new Date(data[currency][i].date).setMinutes(59, 59, 999);
                ranges[currency].push({ rate: data[currency][i].rate, end, start });
            }
        }
    });
    return ranges;
};
export const diffPostData = (postData, ranges) => {
    const diffPost = {};
    if (ranges === null) {
        return Object.assign({}, postData);
    }
    const postDataKeys = Object.keys(postData);
    for (const key of postDataKeys) {
        const k = key.toLowerCase();
        const d = [];
        postData[key].forEach((date) => {
            const mills = new Date(date).getTime();
            const inRange = ranges[k] && ranges[k].some(range => mills > range.start && mills < range.end);
            if (!inRange) {
                d.push(date);
            }
        });
        if (d.length) {
            diffPost[key] = d;
        }
    }
    return Object.keys(diffPost).length === 0 ? null : diffPost;
};
export const RatesProvider = ({ children }) => {
    const serviceRef = useRef(new CurrencyConversionService('usd'));
    const cacheRef = useRef({});
    const ranges = useRef(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [rates, setRates] = useState([]);
    const [historicRates, setHistoricRates] = useState(null);
    const ratesService = new RatesService({ url: '/custody/rates', method: 'get' });
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const getHistoricRates = (ratesFor) => {
        const ratesfor = {};
        // Reduce to hourly rates
        Object.keys(ratesFor).forEach((key) => {
            const seen = {};
            ratesfor[key] = ratesFor[key].filter((dRate) => {
                const hrTimestamp = new Date(dRate).setMinutes(0, 0, 0);
                // eslint-disable-next-line no-return-assign
                return hrTimestamp in seen ? false : (seen[hrTimestamp] = true);
            });
        });
        const data = diffPostData(ratesfor, ranges.current);
        if (data) {
            const url = '/custody/rates/filters';
            const historicRatesService = new OldRatesService({
                url,
                method: 'post',
                baseURL: getEnvSettings().frontendApiUrl,
                data,
            });
            setLoading(true);
            historicRatesService.makeRequest()
                .then((response) => {
                const merged = deepmerge(historicRates || {}, response);
                setLoading(false);
                setHistoricRates(merged);
                ranges.current = deepmerge(ranges.current || {}, ratesRanges(merged));
                if (ranges.current) {
                    serviceRef.current.setHistoricRatesRanges(ranges.current);
                }
            })
                .catch((e) => {
                setLoading(false);
                showNotification({
                    message: e.message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
            cacheRef.current = deepmerge(cacheRef.current, ratesfor);
        }
    };
    useEffect(() => {
        // crypto rates
        ratesService.makeRequest()
            .then((data) => {
            setRates(data.rates);
            serviceRef.current.setRates(data.rates);
            setLoading(false);
        })
            .catch((err) => {
            showNotification({
                message: `Failed to load exchange rates: ${err.message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
        });
    }, []);
    return (React.createElement(RatesContext.Provider, { value: {
            loading,
            rates,
            base: 'eur',
            // eurToUsd,
            historicRates,
            getHistoricRates,
            service: serviceRef.current,
        } }, children));
};
