import { Box, useTheme } from '@mui/material';
import React from 'react';
const CurrencyIcon = (props) => {
    const theme = useTheme();
    if (!props.code) {
        return;
    }
    return (React.createElement(Box, { sx: {
            backgroundColor: theme.palette.background.paper,
            width: '36px',
            height: '36px',
            borderRadius: '50%',
        } },
        React.createElement("img", { width: '36px', height: '36px', src: `/img/currencies/${props.code.toLowerCase()}.svg`, onError: (e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = '/img/currencies/default.svg';
            } })));
};
export default CurrencyIcon;
